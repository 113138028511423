@tailwind base;
@tailwind components;
@tailwind utilities;

html{
    background-color: #f9fafb;
    position: relative;
    height: 100%;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.react-datepicker__close-icon{
    top: -5px!important;
    right: -10px!important;
    display: flex!important;
    padding: 1px!important;
}

.buyers{
    position: relative;
}

.buyers:nth-child(1):after{
    content: "";
    background-image: url("../src/assets/img/moedaouro.png");
    width: 15px;
    height: 15px;
    background-size: 15px;
    display: inline-block;
    position: absolute;
    top: 16px;
    right: 25px;
}
.buyers:nth-child(2):after{
    content: "";
    background-image: url("../src/assets/img/moedaprata.png");
    width: 15px;
    height: 15px;
    background-size: 15px;
    display: inline-block;
    position: absolute;
    top: 16px;
    right: 25px;
}
.buyers:nth-child(3):after{
    content: "";
    background-image: url("../src/assets/img/moedabronze.png");
    width: 15px;
    height: 15px;
    background-size: 15px;
    display: inline-block;
    position: absolute;
    top: 16px;
    right: 25px;
}